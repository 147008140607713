import React from "react";
import Counter from "../common/form/counter";
import { transformShopRemains } from "../../utils/transformShopRemains";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOrder,
  getOrderNumberUuid,
  getOrderRegion,
} from "../../store/order";
import { shopItemImageSelector } from "../../utils/shopItemImageSelector";

function ShopCartItem({ item, shop }) {
  const dispatch = useDispatch();
  const orderRegion = useSelector(getOrderRegion());
  const orderNumberUuid = useSelector(getOrderNumberUuid());
  const handleChange = (value) => {
    dispatch(
      changeOrder({
        orderNumberUuid,
        item: {
          denomination: item.denomination,
          quantity: value,
        },
      })
    );
  };
  return (
    <div className="my-2 flex min-h-[100px] w-full flex-col justify-between gap-2 rounded-lg border bg-[#e9e9e996] px-2 py-2 dark:bg-zinc-900 md:flex-row md:items-center md:px-4">
      <div className="flex w-full items-center justify-between gap-4  md:justify-start ">
        <img
          src={shopItemImageSelector(shop, orderRegion, item.denomination)}
          alt="Номинал"
          className=" w-[105px] rounded-lg object-cover md:w-[150px]"
        />
        <div className=" flex flex-col items-start  ">
          <span>{item.name}</span>
          <div className=" flex flex-nowrap items-center justify-center gap-2  ">
            <div
              className={
                "mt-1 h-2 w-2 rounded-[50%] " +
                (item.remains > 0 ? " bg-green-500" : " bg-red-500")
              }
            ></div>
            <span className="">
              {transformShopRemains(item.remains, item.lotsInStock)}
            </span>
          </div>
        </div>
      </div>
      <div className=" flex w-full flex-row items-center justify-between gap-4 md:w-1/3 md:flex-col md:justify-end  xl:w-1/2 xl:flex-row">
        <div className="">
          <Counter
            quantity={item.quantity}
            onChange={handleChange}
            remains={item.remains}
          />
        </div>
        <span className=" min-w-[100px] text-center">
          {item.finalPriceText}
        </span>
      </div>
    </div>
  );
}

export default ShopCartItem;
