import React from "react";

function Container({ children }) {
  return (
    <div className="dark-text relative mx-auto flex max-w-7xl justify-center">
      <div className="w-full py-2 ">{children}</div>
    </div>
  );
}

export default Container;
