import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   entities: {},
   isLoading: true,
   regionList: [],
   currentRegion: null,
   error: null,
   orderStatus: null,
   payUrl: null
}

export const orderSlice = createSlice({
   name: 'order',
   initialState,
   reducers: {
      changedOrderStatus: (state, action) => {
         state.orderStatus = action.payload;
      },
      addedError: (state, action) => {
         state.error = action.payload;
      },
      clearedError: (state) => {
         state.error = null;
      },
      changedRegion: (state, action) => {
         state.currentRegion = action.payload;
         state.error = null
      },
      getedRegionList: (state, action) => {
         state.regionList = action.payload;
         state.error = null
      },
      orderRequested: (state) => {
         state.isLoading = true;
      },
      getOrder: (state, action) => {
         state.entities = action.payload;
         state.isLoading = false;
         state.error = null
         state.payUrl = null
      },
      changedOrder: (state, action) => {
         state.entities = action.payload;
         state.isLoading = false;
         state.error = null
      },
      billGenerated: (state, action) => {
         state.isLoading = false;
         state.payUrl = action.payload.payUrl
      },
   },
})

export const { getOrder, changedOrder, getedRegionList, changedRegion, addedError, changedOrderStatus, clearedError, orderRequested, billGenerated } = orderSlice.actions

export default orderSlice.reducer