import React, { useState, useEffect } from "react";
import Container from "../hoc/Container";
import {
  BanknotesIcon,
  FireIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import Pagination from "../components/pagination";
import { useResize } from "../hooks/useResize";
import discountService from "../service/discount.service";
import SelectField from "../components/common/form/selectField";
import { useSearchParams } from "react-router-dom";

function DiscountPage({ shop, region, button }) {
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const [country, setCountry] = useState(
    searchParams.get("regionType") || region[0].value
  );
  const { isScreenSm, isScreenMd, isScreenLg } = useResize();
  const [currentPage, setCurrentPage] = useState(1);
  const [gamesCrop, setGamesCrop] = useState([]);
  const [gamesCount, setGamesCount] = useState(0);
  const pageSize = isScreenLg ? 16 : isScreenMd ? 15 : isScreenSm ? 15 : 16;
  const [shopId, setShopId] = useState(
    searchParams.get("platformType") || shop[0].value
  );
  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };
  useEffect(() => {
    const getDiscountCrop = async () => {
      try {
        const content = await discountService.get(
          shopId,
          country,
          pageSize,
          currentPage
        );
        setError(null);
        setGamesCrop(content.results);
        setGamesCount(content.count);
        return content;
      } catch (error) {
        setError(error.response);
      }
    };
    getDiscountCrop();
  }, [currentPage, country, pageSize, shopId]);

  useEffect(() => {
    window.scrollTo({
      top: 107,
      left: 0,
      behavior: "smooth",
    });
  }, [currentPage, isScreenMd, isScreenLg, isScreenSm]);
  const handleChangeCountry = (e) => {
    setCountry(e.value);
    setCurrentPage(1);
  };
  const handleChangeShop = (e) => {
    setShopId(e.value);
    setCurrentPage(1);
  };
  return (
    <Container>
      <section className="content-block">
        <h1 className="head-1 ">
          Скидки {shop.find((s) => s.value === shopId).label}
        </h1>
        <section className=" mx-5 md:flex md:items-end md:justify-between">
          <div className="">
            <p className="mb-2 mt-4 text-center text-xl">Коды пополнения:</p>
            {button}
          </div>
          {shop.length > 1 && (
            <div className="dark:dark-text my-2 flex justify-center">
              <SelectField
                label="Платформа"
                value={shopId}
                onChange={handleChangeShop}
                options={shop}
                name={"shop"}
              />
            </div>
          )}
          {region.length > 1 && (
            <div className="dark:dark-text my-2 flex justify-center">
              <SelectField
                label="Регион"
                value={country}
                onChange={handleChangeCountry}
                options={region}
                name={"region"}
              />
            </div>
          )}
        </section>

        {error && error.status >= 400 ? (
          <h3 className=" mt-8 text-center text-2xl text-red-500">
            Ошибка получения данных, попробуйте повторить позже
          </h3>
        ) : (
          <>
            <div className=" mt-4">
              {" "}
              <Pagination
                itemsCount={gamesCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
            <section className="m-4 grid grid-cols-2 gap-4 text-sm sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4">
              {!!gamesCrop.length
                ? gamesCrop.map((game) => (
                    <div className=" " key={game.gameId}>
                      <a
                        href={`/api/v1/game/?gameId=${game.gameId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="relative">
                          <img
                            src={game.cover}
                            alt={game.name}
                            className=" aspect-square w-full rounded object-contain transition hover:scale-105"
                          />
                          <div className=" absolute bottom-0 left-0 rounded-sm bg-white p-0.5 font-mono text-xs text-black">
                            <FireIcon className=" inline-block w-3.5  text-orange-500 " />
                            {game.discountPercent}%
                          </div>
                        </div>
                      </a>
                      <div className="flex justify-between pt-2">
                        <div className="flex justify-center text-base">
                          <BanknotesIcon className=" inline-block w-3.5 text-green-500 " />
                          <span className="ml-2  font-bold ">{game.price}</span>
                          <span className="ml-2 text-gray-500 line-through">
                            {game.originalPrice}
                          </span>
                        </div>
                      </div>
                      {game.discountEndDate && (
                        <p className=" pb-2 text-xs">
                          <ClockIcon className=" mr-2 inline-block w-3.5   text-red-500" />
                          до{" "}
                          {new Date(game.discountEndDate).toLocaleDateString(
                            "ru",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </p>
                      )}
                      <a
                        href={`/api/v1/game/?gameId=${game.gameId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h2
                          className=" text-sm font-light hover:underline"
                          key={game.id}
                        >
                          {game.name}
                        </h2>
                      </a>
                    </div>
                  ))
                : null}
            </section>
            <p className=" dark:dark-text mb-1 text-center text-xs">
              {1 + pageSize * (currentPage - 1)} -{" "}
              {pageSize + pageSize * (currentPage - 1)} из {gamesCount}
            </p>
            <Pagination
              itemsCount={gamesCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
            <div className="mt-10 text-center text-xs">
              * Данные по скидкам получены через API сайта{" "}
              <a
                href="https://psprices.com"
                className="text-blue-700 dark:text-indigo-400"
                target="_blank"
                rel="noreferrer"
              >
                psprices.com
              </a>
            </div>
          </>
        )}
      </section>
    </Container>
  );
}

export default DiscountPage;
