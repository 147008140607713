import React, { useEffect } from "react";
import Container from "../hoc/Container";
import { steam_tg_img, tgIcon } from "../../images";
// import ConvertCourse from "../components/convertCourse";

function SteamPage() {
  useEffect(() => {
    window.ym(93997310, "hit", "/steam", {
      params: {
        title: "Steam",
        referer: "https://trendy-eng-shop.ru/",
      },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Container>
      <section className="content-block mb-2">
        <h1 className="head-1">Карты Steam</h1>
        <div className="mb-8 block items-center justify-between space-x-2 px-2 md:flex">
          <div className="flex basis-2/5 justify-center md:justify-start">
            <a
              className=" t flex w-1/2 justify-center md:w-1/2"
              href={"https://t.me/trendy_steam_bot?start=ref-001"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className=" rounded-lg border-2 border-gray-500 hover:shadow-sm  hover:shadow-zinc-900 dark:border-gray-500 dark:hover:border-gray-50"
                src={steam_tg_img}
                alt="Steam Trendy Shop Telegram"
                onClick={() => window.ym(93997310, "reachGoal", "tgClickSteam")}
              />
            </a>
          </div>
          <div className=" flex basis-3/5 flex-col items-center justify-center">
            <span className=" dark-text mt-2  w-full text-center text-xl text-gray-700 md:mt-0">
              Карты в наличии!
            </span>
            <div className="my-2 w-full overflow-scroll rounded-lg border bg-slate-100 p-2 dark:border-slate-500 dark:bg-slate-600">
              <table className=" w-full whitespace-nowrap text-center">
                <thead>
                  <tr>
                    <th className="dark-text w-1/5 border-b-2 dark:border-slate-500 dark:text-white ">
                      Регион
                    </th>
                    <th className="dark-text border-b-2 dark:border-slate-500 dark:text-white">
                      Номиналы
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="dark-text  border-b-2 dark:border-slate-500">
                      EU (HKD)
                    </td>
                    <td className="dark-text border-b-2 dark:border-slate-500">
                      <span className="mx-3">40 </span>
                      <span className="mx-3">80</span>
                      <span className="mx-3">160</span>
                      <span className="mx-3">200</span>
                      <span className="mx-3">500</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              onClick={() => window.ym(93997310, "reachGoal", "tgClickSteam")}
            >
              <a
                href={"https://t.me/trendy_steam_bot?start=ref-001"}
                className="flex w-40 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                target="_blank"
                rel="noreferrer"
              >
                Купить в <img src={tgIcon} alt="TG" className=" ml-2" />
              </a>
            </div>
            {/* <ConvertCourse courseNow={0.017594} /> */}
          </div>
        </div>
      </section>
      <article className="content-block text-justify">
        <section className=" my-5 ">
          <h3 className="my-2 text-center text-2xl overline decoration-sky-500">
            Описание
          </h3>
          <p className=" dark-text my-3 w-full indent-4 text-gray-700">
            С помощью нашего бота в телеграм вы можете легко, просто и удобно
            купить карты оплаты для Steam, пополнить кошелек Steam и купить
            любую интересующую вас игру. Или можете сделать отличный подарок
            геймеру с помощью подарочных карт Steam. Только перед началом нужно
            выбрать регион (см. особенности регионов) и желательно приобрести
            новый аккаунт нужного региона (есть в наличии у бота).
          </p>
        </section>
        <section className=" my-5">
          <h3 className="my-2 text-center text-2xl">
            Подарочная карта Steam Gift Card
          </h3>
          <p className=" dark-text my-3 w-full indent-4 text-gray-700">
            Наши карты от официальных дистрибьютеров, и они БЕССРОЧНЫЕ, то есть
            можно купить сейчас, а активировать когда вам будет удобно. Это еще
            одна причина почему Steam Gift Card – это прекрасный подарок для
            любого компьютерного геймера.
          </p>
          <p className=" dark-text my-3 w-full indent-4 text-gray-700">
            Подарочная карта представляет собой специальный 15-значный код,
            который необходимо ввести в приложении Steam (Об аккаунте -&gt;
            Пополнить баланс -&gt; Код кошелька или подарочной карты Steam). Или
            на{" "}
            <a
              href="https://store.steampowered.com/account/redeemwalletcode"
              className="text-blue-700 dark:text-indigo-400"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              сайте Steam{" "}
            </a>
            зайдя под своей учетной записью. С ее помощью удается получить
            средства на балансе, сумма которых кратна номиналу карты.
          </p>
          <p className=" dark-text my-3 w-full indent-4 text-gray-700">
            Использовать такой способ пополнения можно как для уже существующих
            аккаунтов, так и для новых. Мы же рекомендуем выбирать регион
            Казахстан.
          </p>
        </section>
        <section className=" my-5">
          <h3 className="my-2 text-center text-2xl">
            Как использовать Steam Gift Card?
          </h3>
          <p className=" dark-text my-3 w-full indent-4 text-gray-700">
            У бота в телеграм есть самые подробные инструкции по команде /help
          </p>
        </section>
        <section className=" my-5">
          <h3 className="my-2 text-center text-2xl">Про аккаунты</h3>
          <p className=" dark-text my-3 w-full indent-4 text-gray-700">
            У нас только новозарегистрированные аккаунты специально для продажи.
            После смены почты, пароля и установки двухфакторной защиты, аккаунт
            становится полностью вашим и только вашим.
          </p>
        </section>
        <section className=" my-5">
          <h3 className="my-2 text-center text-2xl">Особенности регионов</h3>
          <p className=" dark-text my-3 w-full indent-4 text-gray-700">
            Активировать карту можно находясь в любой стране, без использования
            VPN.
          </p>
          <section>
            <h4 className="my-2 indent-4 text-base">
              Особенности Казахстанского региона:
            </h4>

            <ul className="dark-text ms-10 list-inside list-decimal text-gray-700">
              <li>
                Курс плавает и нужно проверять соотношение HKD и тенге (KZT) в
                поисковике
              </li>
              <li>
                Можно за недорого переключить Россию на Казахстан (не у нас)
              </li>
            </ul>
          </section>
        </section>
      </article>
    </Container>
  );
}

export default SteamPage;
