import React from "react";
import Container from "../hoc/Container";
import { Link } from "react-router-dom";

function CookiesInfoPage() {
  return (
    <Container>
      <article className="content-block text-justify">
        <section className=" my-5 ">
          <h3 className="my-2 text-center text-2xl ">
            Информация о сборе пользовательских данных
          </h3>
          <p className=" dark-text my-3 w-full indent-4 text-gray-700">
            Когда вы посещаете этот сайт,{" "}
            <Link to={"/"} className="text-blue-700 dark:text-indigo-400">
              trendy-eng-shop.ru{" "}
            </Link>
            может использовать общеотраслевую технологию, называемую cookie.
            Файлы cookie представляют собой небольшие фрагменты данных, которые
            временно сохраняются на вашем компьютере или мобильном устройстве и
            обеспечивают более эффективную работу сайта. trendy-eng-shop для
            сбора статистики использует Яндекс Метрика. На основе этих данных мы
            делаем наш сайт лучше и эффективнее для пользователей. Продолжая
            пользоваться этим сайтом, вы соглашаетесь на использование cookie и
            обработку данных. Если вы не хотите использовать cookie, вы можете отключить
            их в настройках безопасности вашего браузера. Отключение cookie
            следует выполнить для каждого браузера и устройства, с помощью
            которого осуществляется вход на сайт. Обратите внимание, что в
            случае, если использование сайтом cookie-файлов отключено, некоторые
            возможности и услуги сайта могут быть недоступны.
          </p>
        </section>
        <section className=" my-5">
          <h3 className="my-2 text-center text-2xl">
            Согласие на обработку персональных данных посетителей сайта
          </h3>
          <p className=" dark-text my-3 w-full indent-4 text-gray-700">
            Настоящим, свободно, своей волей и в своем интересе выражаю свое
            согласие ИП Казакову Игорю Евгеньевичу{" "}
            <Link to={"/"} className="text-blue-700 dark:text-indigo-400">
              trendy-eng-shop.ru{" "}
            </Link>{" "}
            (далее – Оператор) на автоматизированную обработку моих персональных
            данных в соответствии со следующим перечнем персональных данных: мои
            запросы как посетителя сайта; системная информация, данные из моего
            браузера; файлы cookie; мой IP-адрес; установленные на моем
            устройстве операционные системы; установленные на моем устройстве
            типы браузеров; установленные на моем устройстве расширения и
            настройки цвета экрана; установленные и используемые на моем
            устройстве языки; версии Flash и поддержка JavaScript; типы
            мобильных устройств, используемых мной, если применимо;
            географическое положение; количество посещений сайта и просмотров
            страниц; длительность пребывания на сайте; запросы, использованные
            мной при переходе на сайт; страницы, с которых были совершены
            переходы. Для целей сбора статистики о посетителях сайта Оператор
            вправе осуществлять обработку моих персональных данных следующими
            способами: сбор, систематизация, накопление, хранение, обновление,
            изменение, использование. Оператор может обрабатывать файлы cookie
            самостоятельно или с привлечением иных сервисов. Настоящее согласие
            вступает в силу с момента моего перехода на сайт Оператора и
            действует до момента его отзыва. Согласие действует на период: 1
            год. Согласие на обработку персональных данных может быть отозвано
            мною путем письменного уведомления по указанному адресу об этом не
            менее чем за один месяц до даты фактического прекращения обработки
            моих персональных данных Оператором.
          </p>
        </section>
      </article>
    </Container>
  );
}

export default CookiesInfoPage;
