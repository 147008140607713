import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentRegion,
  getOrderRegion,
  getRegionList,
  getRegions,
} from "../../store/order";
import { changedRegion } from "../../store/order/orderSlice";

function ShopRegionList({ shop }) {
  const regionList = useSelector(getRegions());
  const currentRegion = useSelector(getCurrentRegion());

  useEffect(() => {
    getAllRegionsList();
  }, []);
  const dispatch = useDispatch();
  const handleChangeRegion = (value) => {
    dispatch(changedRegion(value));
  };
  const getAllRegionsList = async () => {
    dispatch(getRegionList(shop));
  };
  return (
    <div className=" flex w-full items-center justify-center gap-4 py-2">
      {regionList?.map((reg) => (
        <button
          className={
            " mx-2 flex  justify-center rounded-md border bg-indigo-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white outline outline-indigo-800 transition duration-300 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-800 disabled:hover:bg-indigo-800 " +
            (currentRegion === reg.cardRegionCd
              ? " cursor-default shadow-md shadow-black dark:shadow-white "
              : "   border-indigo-800 ")
          }
          disabled={currentRegion === reg.cardRegionCd}
          key={reg.cardRegionCd}
          onClick={() => handleChangeRegion(reg.cardRegionCd)}
        >
          {reg.cardRegionCd} ( {reg.regionCaption} )
        </button>
      ))}
    </div>
  );
}

export default ShopRegionList;
