import httpService from './http.service';

const regionEndPoint = `order/region/`;
const orderEndPoint = "order/";
const orderChangeEndPoint = `order/change/`;
const orderStatusEndPoint = `order/status/`
const billEndPoint = `bill/generate/`;



const shopService = {

   getRegionsByShopName: async (region = "eshop") => {
      const { data } = await httpService.get(regionEndPoint + region + "/");
      return data;
   },
   getOrderByShopAndRegion: async (shop, params) => {
      const { data } = await httpService.get(orderEndPoint + shop + "/", {
         ...params,
      });
      return data;
   },
   changeOrder: async (body) => {
      const { data } = await httpService.post(orderChangeEndPoint, body);
      return data;
   },
   getOrderStatus: async (params) => {
      const { data } = await httpService.get(orderStatusEndPoint, params);
      return data;
   },
   generateBill: async (body) => {
      const { data } = await httpService.post(billEndPoint, body);
      return data;
   },

}

export default shopService;