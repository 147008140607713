import SteamPage from './app/layouts/steamPage';
import PlayStationPage from './app/layouts/playStationPage';
import MainPage from './app/layouts/mainPage';
import NintendoDiscount from './app/layouts/nintendoDiscount';
import SteamDiscount from './app/layouts/steamDiscount';
import PlayStationDiscount from './app/layouts/playStationDiscount';
import NintendoShop from './app/layouts/nintendoShop';
import { Navigate } from 'react-router-dom';
import CookiesInfoPage from './app/layouts/cookiesInfoPage';

export const routes = [
   { path: "/", element: <MainPage />, display: false, title: "Главная" },
   { path: "/nintendo-eshop", element: <Navigate to={"/nintendo-eshop/shop"} />, display: false, title: "Магазин Nintendo" },
   { path: "/nintendo-eshop/shop", element: <NintendoShop />, display: true, title: "Магазин Nintendo", group: "nintendo", shortName: "Магазин" },
   { path: "/nintendo-discount", element: <NintendoDiscount />, display: true, title: "Скидки Nintendo", group: "nintendo", shortName: "Скидки" },
   { path: "/steam", element: <SteamPage />, display: true, title: "Карты Steam", group: "steam", shortName: "Пополнение" },
   { path: "/steam-discount", element: <SteamDiscount />, display: true, title: "Скидки Steam", group: "steam", shortName: "Скидки" },
   { path: "/playstation", element: <PlayStationPage />, display: true, title: "Карты PlayStation", group: "playstation", shortName: "Пополнение" },
   { path: "/playstation-discount", element: <PlayStationDiscount />, display: true, title: "Скидки PlayStation", group: "playstation", shortName: "Скидки" },
   { path: "/cookies-info", element: <CookiesInfoPage />, display: false, title: "Мы используем cookie" },
   { path: "*", element: <Navigate to={"/"} />, display: false },
]
