import React, { useState } from "react";
import { useModal } from "../../hooks/useModal";
import { useDispatch } from "react-redux";
import { clearedError } from "../../store/order/orderSlice";

function Modal() {
  const dispatch = useDispatch();
  const { isModalOpen, modalMedia, closeModal } = useModal();
  const handleClick = () => {
    dispatch(clearedError());
    closeModal();
  };
  return (
    isModalOpen && (
      <div className=" fixed left-0 top-0 z-10 flex h-screen w-screen  items-center justify-center bg-black  bg-opacity-60">
        <div className="  max-w-1/2 flex min-w-[200px] flex-col items-center justify-center gap-4 rounded-lg bg-slate-200 p-4 text-black dark:bg-slate-700 dark:text-white">
          <span>{modalMedia}</span>
          <button
            className=" mx-2 flex  w-[50px] justify-center rounded-md bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm  transition duration-300 hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
            onClick={() => handleClick()}
          >
            OK
          </button>
        </div>
      </div>
    )
  );
}

export default Modal;
