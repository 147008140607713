import React, { useEffect, useState } from "react";
import { routes } from "../../routes";
import { NavLink } from "react-router-dom";

function NavItem({ name, group }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="relative  px-3 "
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="flex items-center justify-center">
        <button
          className={
            isOpen
              ? "-mx-3 block  px-3  py-2  text-xl font-semibold leading-7   text-blue-700  dark:text-blue-300 md:text-center "
              : "-mx-3 block  px-3 py-2  text-xl  font-semibold leading-7  text-blue-500  dark:text-[#cccccc] md:text-center"
          }
          type="button"
          aria-controls="disclosure-1"
          aria-expanded="false"
        >
          {name}
        </button>
      </div>
      {isOpen && (
        <div
          className={
            "born-item absolute right-0 top-full z-10 flex flex-col rounded-lg bg-gray-200 px-4 py-2 dark:bg-zinc-900 "
          }
        >
          {routes.map((route, ind) => {
            if (route.group === group) {
              return (
                <NavLink
                  key={ind}
                  to={route.path}
                  className="nav-link whitespace-nowrap"
                  onClick={() => setIsOpen(false)}
                >
                  {route.shortName}
                </NavLink>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}

export default NavItem;
