import React from "react";

const SelectField = ({
  label,
  value,
  onChange,
  defaultOption,
  options,
  error,
  name,
}) => {
  const isInvalidClass = () => {
    return "form-select " + (error ? "is-invalid" : "");
  };
  const handleChange = (e) => {
    onChange({ value: e.target.value });
  };

  return (
    <div className="">
      <label htmlFor={name} className="">
        {label}
      </label>
      <select
        className={isInvalidClass()}
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
      >
        {defaultOption && (
          <option disabled value="">
            {defaultOption}
          </option>
        )}
        {options.length > 0 &&
          options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default SelectField;
