import shopService from "../../service/shop.service";
import {getSbtPayUrl} from "../../utils/payUrlGenerator"
import { changedOrder, changedRegion, getOrder, getedRegionList, addedError, changedOrderStatus, orderRequested, billGenerated } from "./orderSlice";


export const getRegionList = (shop) => async (dispatch) => {
   try {
      const { regionList } = await shopService.getRegionsByShopName(shop);
      dispatch(getedRegionList(regionList));
      dispatch(changedRegion(regionList[0].cardRegionCd))
   } catch (error) {
      const message = error?.response?.data?.message ? error?.response?.data?.message : error?.message;
      dispatch(addedError(message));
   }
}


export const getOrderByShopAndRegion = (shop, params) => async (dispatch) => {
   dispatch(orderRequested())
   try {
      const data = await shopService.getOrderByShopAndRegion(shop, params);
      dispatch(getOrder(data));
   } catch (error) {
      const message = error?.response?.data?.message ? error?.response?.data?.message : error?.message;
      dispatch(addedError(message));
   }
};

export const changeOrder = (body) => async (dispatch) => {
   try {
      const data = await shopService.changeOrder(body);
      dispatch(changedOrder(data));
   } catch (error) {
      const message = error?.response?.data?.message ? error?.response?.data?.message : error?.message;
      dispatch(addedError(message));
   }
};

export const buyForOrder = (body) => async (dispatch) => {
   try {
      const data = await shopService.generateBill(body);
      dispatch(billGenerated(data));
      if (data.payUrl) {
         window.open(getSbtPayUrl(data.payUrl), '_blank', 'noreferrer');
      }
      return data;
   } catch (error) {
      const message = error?.response?.data?.message ? error?.response?.data?.message : error?.message;
      dispatch(addedError(message));
   }
};

export const getOrderStatus = (params) => async (dispatch) => {
   try {
      const data = await shopService.getOrderStatus(params);
      dispatch(changedOrderStatus(data));
      return data;
   } catch (error) {
      const message = error?.response?.data?.message ? error?.response?.data?.message : error?.message;
      dispatch(addedError(message));
   }
};