import { useState } from "react";
import { Dialog, Popover } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import { NavLink, Link } from "react-router-dom";
import { routes } from "../../routes";
import { trendyLogo_img } from "../../images";
import NavItem from "./navItem";

export default function Example() {
  const [showToolTip, setShowToolTip] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    setShowToolTip(true);
  };
  const handleMouseLeave = () => {
    setShowToolTip(false);
  };
  return (
    <>
      <nav
        className="mx-auto flex  max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        {routes.map((route, ind) => {
          if (route.path === "/") {
            return (
              <Link to={route.path} key={ind}>
                <img
                  className="ping-one-time  animation-delay-2 w-[200px] basis-1/5"
                  src={trendyLogo_img}
                  alt="Trendy Shop"
                />
              </Link>
            );
          }
          return null;
        })}

        <div className="flex md:hidden">
          <a
            href={"https://t.me/tp_trendy_eng_shop"}
            target="_blank"
            rel="noreferrer"
          >
            <ChatBubbleLeftRightIcon className=" me-8 h-6 w-6  text-green-500 dark:text-green-500" />
          </a>
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>

            <Bars3Icon className="h-6 w-6 dark:text-white" aria-hidden="true" />
          </button>
        </div>

        <Popover.Group className="hidden md:flex md:items-center md:justify-center md:space-x-10">
          <NavItem name="Nintendo" group="nintendo" />
          <NavItem name="Steam" group="steam" />
          <NavItem name="PlayStation" group="playstation" />
          <a
            href={"https://t.me/tp_trendy_eng_shop"}
            target="_blank"
            rel="noreferrer"
            className=" relative inline-block"
          >
            <ChatBubbleLeftRightIcon
              className=" text-green-500 hover:text-green-400    md:w-10 lg:w-10"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            {
              <>
                <span
                  className={
                    "absolute right-2  block whitespace-nowrap rounded-md border border-green-500 p-1  text-xs  text-green-500  duration-300 md:-bottom-[30px] lg:-bottom-[30px] " +
                    (showToolTip ? " opacity-100 " : " invisible opacity-0")
                  }
                >
                  Служба поддержки
                  <span className=" absolute -top-[5px] right-4 block h-2 w-2 -rotate-45 border-r border-t  border-green-500  bg-[#f7f8fa] dark:bg-[#222222] ">
                    {""}
                  </span>
                </span>
              </>
            }
          </a>
        </Popover.Group>
      </nav>
      <Dialog
        as="div"
        className=" md:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />

        <Dialog.Panel className=" fixed inset-y-0 right-0 z-10 w-full overflow-y-auto  bg-[#dce1e6]  dark:bg-[#141414] sm:ring-1 sm:ring-gray-900/10">
          <div className="flex justify-center px-2">
            <div className="container ">
              <div className=" flex items-center justify-between rounded-b-xl bg-[#f7f8fa] px-6 py-6 dark:bg-[#222222]">
                {routes.map((route, ind) => {
                  if (route.path === "/") {
                    return (
                      <Link
                        key={ind}
                        to={route.path}
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <img
                          className=" w-[200px]"
                          src={trendyLogo_img}
                          alt=" Trendy Shop"
                        />
                      </Link>
                    );
                  }
                  return null;
                })}

                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon
                    className="h-6 w-6 dark:text-white "
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className=" mt-2 flow-root rounded-3xl bg-[#f7f8fa]  px-6 py-6 dark:bg-[#222222]">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {routes.map((route, ind) => {
                      if (route.display) {
                        return (
                          <NavLink
                            key={ind}
                            to={route.path}
                            className="nav-link"
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {route.title}
                          </NavLink>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
}
