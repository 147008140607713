import React from "react";
import Container from "../hoc/Container";

function SteamDiscount() {
  return (
    <Container>
      <div className="content-block head-1">
        Скоро тут будут лучшие предложения Steam
      </div>
    </Container>
  );
}

export default SteamDiscount;
