import httpService from './http.service';

const discountEndPoint = `discount/`;



const discountService = {

   get: async (platform, region, pageSize, currentPage) => {
      const { data } = await httpService.get(discountEndPoint + `?platformType=${platform}&regionType=${region}&pageSize=${pageSize}&pageNumber=${currentPage}`);
      return data;
   },
   fetchAll: async () => {
      const { data } = await httpService.get(discountEndPoint);
      return data;
   }

}

export default discountService;