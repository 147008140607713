import React, { useEffect } from "react";
import ShopCartItem from "./shopCartItem";
import ShopCartTotal from "./shopCartTotal";
import ShopCartPaymentInfo from "./shopCartPaymentInfo";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrder,
  getOrderIsLoading,
  getOrderByShopAndRegion,
  getPayUrl,
} from "../../store/order";
import { getCurrentRegion } from "../../store/order/selectors";
import Loader from "../loader";

function ShopCart({ shop }) {
  const isLoadingOrder = useSelector(getOrderIsLoading());
  const currentRegion = useSelector(getCurrentRegion());
  const order = useSelector(getOrder());
  const payUrl = useSelector(getPayUrl());
  const dispatch = useDispatch();
  const getFullOrder = async () => {
    dispatch(
      getOrderByShopAndRegion(shop, {
        params: { regionType: currentRegion },
      })
    );
  };
  useEffect(() => {
    if (currentRegion) getFullOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion]);
  return (
    <>
      {Object.entries(order).length > 0 && (
        <div className=" w-full">
          <div className=" flex items-center justify-between">
            <h2 className=" text-2xl font-medium">{order.orderTitle}</h2>
          </div>

          <div className=" flex flex-col gap-4 lg:flex-row">
            <div className=" w-full lg:w-2/3  xl:w-3/4">
              {order.itemList?.map((item, index) => (
                <ShopCartItem item={item} key={index} shop={shop} />
              ))}
            </div>
            <div className=" w-full lg:w-1/3 xl:w-1/4 ">
              <ShopCartTotal cart={order.cart} />
            </div>
          </div>

          {payUrl && (
            <div className=" w-full ">
              <ShopCartPaymentInfo />
            </div>
          )}
        </div>
      )}
      <Loader loading={isLoadingOrder} />
    </>
  );
}

export default ShopCart;
