// import eshop_pl_70 from "./eshop-pl-70.png";
// import eshop_pl_120 from "./eshop-pl-120.png";
// import eshop_pl_250 from "./eshop-pl-250.png";
// import eshop_us_10 from "./eshop-us-10.png";
// import eshop_us_20 from "./eshop-us-20.png";
// import eshop_us_35 from "./eshop-us-35.png";
// import eshop_us_50 from "./eshop-us-50.png";

// export { eshop_pl_70, eshop_pl_120, eshop_pl_250, eshop_us_10, eshop_us_20, eshop_us_35, eshop_us_50 }
function importAll(r) {
   return r.keys().map(r);
}

export const allImagesNew = importAll(require.context('./', false, /\.(png|jpe?g|svg)$/));