import React from "react";
import {useSelector} from "react-redux";
import {getOrderNumber, getPayUrl} from "../../store/order/selectors";
import {getCardPayUrl, getSbtPayUrl} from "../../utils/payUrlGenerator"

function ShopCartPaymentInfo() {
  const orderNumber = useSelector(getOrderNumber());
  const payUrl = useSelector(getPayUrl());
  return (
    <div className=" relative my-2 flex flex-col items-center rounded-lg border bg-[#e9e9e996] px-2 py-2 dark:bg-zinc-900 ">
      <div className=" mb-2 flex flex-col items-center justify-between gap-2">
        <div className=" flex flex-col gap-0">
          <span className=" font-bold">Оплата заказа:</span>
          <span>Для оплаты заказа # {orderNumber} воспользуйтесь ссылкой: <a href={getSbtPayUrl(payUrl)} className=" link" target="_blank" rel="noreferrer">Оплатить</a></span>
          <span>Если у вас не получилось оплатить по ссылке выше, то воспользуйтесь этой ссылкой: <a href={getCardPayUrl(payUrl)} className=" link" target="_blank" rel="noreferrer">Оплатить (способ 2)</a></span>
          <br/>
          <span>Для получения кодов, вам необходимо оплатить счёт в течение 10 мин. После успешной оплаты коды придут на указанную в заказе почту в течение пары минут (редко дольше - до 25 минут).</span>
          <br/>
          <span><span className=" text-red-600 font-bold">Внимание!</span> Если вы оплатили заказ, но письмо с кодами не приходит, то проверьте папку <span className=" font-bold">Спам</span>.</span>
          <span>Если оно оказалось с этой папке, отметьте пожалуйста письмо как <span className=" font-bold">Не спам.</span></span>
        </div>
      </div>
    </div>
  );
}

export default ShopCartPaymentInfo;
