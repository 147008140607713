import React from "react";
import _ from "lodash";

function Pagination({ itemsCount, pageSize, onPageChange, currentPage }) {
  const pageCount = Math.ceil(itemsCount / pageSize);
  const pages = _.range(1, pageCount + 1);
  return (
    <div className="flex  w-full  justify-center">
      <nav
        className="isolate inline-flex  -space-x-px rounded-md shadow-sm"
        aria-label="Pagination"
      >
        <button
          className={
            currentPage === 1
              ? "relative inline-flex items-center rounded-l-md px-2 py-2 opacity-50 ring-1 ring-inset ring-gray-300 dark:text-white"
              : "relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-20 focus:outline-offset-0 dark:text-white dark:hover:bg-gray-500"
          }
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <span className="sr-only">Previous</span>
          <svg
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
        {pages.length < 6
          ? pages.map((page) => (
              <button
                key={page}
                className={currentPage === page ? "pagItemActive" : "pagItem"}
                onClick={() => onPageChange(page)}
              >
                {page}
              </button>
            ))
          : pages.map((page) => {
              if (currentPage <= 3) {
                if (page <= 7)
                  return (
                    <button
                      key={page}
                      className={
                        currentPage === page ? "pagItemActive" : "pagItem"
                      }
                      onClick={() => onPageChange(page)}
                    >
                      {page}
                    </button>
                  );
              } else if (currentPage >= pages.length - 3) {
                if (page > pages.length - 7)
                  return (
                    <button
                      key={page}
                      className={
                        currentPage === page ? "pagItemActive" : "pagItem"
                      }
                      onClick={() => onPageChange(page)}
                    >
                      {page}
                    </button>
                  );
              } else {
                if (page < currentPage + 4 && page >= currentPage - 3)
                  return (
                    <button
                      key={page}
                      className={
                        currentPage === page ? "pagItemActive" : "pagItem"
                      }
                      onClick={() => onPageChange(page)}
                    >
                      {page}
                    </button>
                  );
              }

              return null;
            })}

        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === pages.at(-1)}
          className={
            currentPage === pages.at(-1)
              ? "relative inline-flex items-center rounded-r-md px-2 py-2 opacity-50 ring-1 ring-inset ring-gray-300 dark:text-white"
              : "relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-20 focus:outline-offset-0 dark:text-white dark:hover:bg-gray-500"
          }
        >
          <span className="sr-only">Next</span>
          <svg
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </nav>
    </div>
  );
}

export default Pagination;
