import React from "react";
import Container from "../../hoc/Container";
import {tgIcon, vkIcon} from "../../../images";

function NintendoInfo() {

  return (
    <Container>
      <article className=" content-block-no-top-bottom-padding">
        <section className="my-5">
          <h3 className="my-2 text-center text-2xl overline decoration-sky-500">
            Боты для покупки карт оплаты
          </h3>
          <div
              onClick={() =>
                  window.ym(93997310, "reachGoal", "tgClickNintendo")
              }
              className="flex flex-col items-center justify-center gap-2 sm:flex-row sm:gap-4 "
          >
            <a
                href={"https://t.me/trendy_eng_shop_bot?start=ref-001"}
                className="flex w-40 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                target="_blank"
                rel="noreferrer"
            >
              Купить в <img src={tgIcon} alt="TG" className=" ml-2" />
            </a>
            <a
                href={"https://vk.com/trendy_eng_shop"}
                className=" mx-2 flex w-40 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                target="_blank"
                rel="noreferrer"
            >
              Купить в{" "}
              <img src={vkIcon} alt="VK" className=" ml-2 scale-125" />
            </a>
          </div>
        </section>
        <section className="my-5">
          <h3 className="my-2 text-center text-2xl overline decoration-sky-500">
            Описание
          </h3>
          <p className=" dark-text my-3 w-full text-justify indent-4 text-gray-700">
            С помощью нашего интернет-магазина вы можете легко, просто и удобно
            купить карты оплаты для Nintendo Switch, пополнить свой кошелек
            Nintendo eShop и затем купить любую интересующую вас игру. Или можете
            сделать отличный подарок геймеру с помощью подарочных карт Nintendo.
          </p>
          <p className=" dark-text my-3 w-full text-justify indent-4 text-gray-700">
            Только перед покупкой необходимо выбрать правильный регион. В
            <a
              href="https://accounts.nintendo.com/profile/edit"
              className="text-blue-700 dark:text-indigo-400"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              личном кабинете{" "}
            </a>
            Nintendo, в разделе Страна/регион нужно указать США или Польша, а
            потом купить у бота карту соответствующую этому региону.
          </p>
        </section>
        <section className="my-5">
          <h3 className="my-2 text-center text-2xl">
            Подарочная карта Nintendo Gift Card
          </h3>
          <p className=" dark-text my-3 w-full text-justify indent-4 text-gray-700">
            Наши карты можно купить сейчас, а активировать когда вам будет удобно. Это еще
            одна причина почему Nintendo Gift Card – это прекрасный подарок для
            любого фаната игр Nintendo.
          </p>
          <p className=" dark-text my-3 w-full text-justify indent-4 text-gray-700">
            Подарочная карта представляет собой специальный 16-значный код,
            который необходимо ввести на Nintendo Switch в Nintendo eShop. Или
            на
            <a
              href="https://accounts.nintendo.com/profile/edit"
              className="text-blue-700 dark:text-indigo-400"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              сайте Nintendo
            </a>
            , зайдя под своей учетной записью. С ее помощью удается получить
            средства на балансе, сумма которых кратна номиналу карты.
          </p>
          <p className=" dark-text my-3 w-full text-justify indent-4 text-gray-700">
            Использовать такой способ пополнения можно как для уже существующих
            аккаунтов, так и для новых. Мы же рекомендуем выбирать безналоговые
            штаты USA при регистрации нового аккаунта. Это позволит избежать
            дополнительных комиссий при покупке игр.
          </p>
        </section>
        <section className="my-5">
          <h3 className="my-2 text-center text-2xl">
            Как использовать Nintendo Gift Card?
          </h3>
          <p className=" dark-text my-3 w-full text-justify indent-4 text-gray-700">
            Специально для вас ответили на самые часто возникающие вопросы -
            <a
                href="https://vk.com/@trendy_eng_shop-otvety-na-vse-voprosy"
                className="text-blue-700 dark:text-indigo-400"
                target="_blank"
                rel="noreferrer"
            >
              {" "}
              Ответы!
            </a>
          </p>
        </section>
        <section className="mt-5">
          <h3 className="my-2 text-center text-2xl">Особенности регионов</h3>
          <p className=" dark-text my-3 w-full text-justify indent-4 text-gray-700">
            Активировать карту можно находясь в любой стране, без использования
            VPN.
          </p>
          <section>
            <h4 className="my-2 indent-4 text-base">
              Особенности Польского региона:
            </h4>

            <ul className="dark-text ms-10 list-inside list-decimal text-gray-700">
              <li>Скидки обычно завозят чуть позже</li>
              <li>DLC подходят для игр, купленных ранее в регионе Россия</li>
              <li>При уходе из региона сгорает баланс</li>
            </ul>
          </section>
          <section>
            <h4 className="my-2 indent-4 text-base">
              Особенности региона США:
            </h4>

            <ul className="dark-text ms-10 list-inside list-decimal text-gray-700">
              <li>Нельзя уйти с региона без обнуления баланса</li>
              <li>
                DLC региона US чаще всего не работают на играх, купленных ранее
                в регионе Россия
              </li>
              <li>
                Чтобы не платить дополнительный налог нужно вводить Zip code -
                97201
              </li>
            </ul>
          </section>
        </section>
      </article>
    </Container>
  );
}

export default NintendoInfo;
