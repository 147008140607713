import { Routes, Route } from "react-router-dom";
import Example from "./app/components/exampleNav";
import { routes } from "./routes";
import { ModalProvider } from './app/hooks/useModal';
import Modal from './app/components/common/modal';
import CookieToaster from "./app/components/cookieToaster";



function App() {
  const getRoutes = (routes) => {
    return routes.map((rote, ind) => { return <Route path={rote.path} element={rote.element} key={ind} /> })
  }

  return (
    <div className=" flex justify-center relative min-h-screen ">
      <div className="flex flex-col items-center w-full max-w-7xl">
        <header className="bg-[#f7f8fa] dark:bg-[#222222] rounded-b-2xl container ">
          <Example />
        </header >
        <main className="h-full  w-full container">
          <ModalProvider>
            <Routes>
              {getRoutes(routes)}
            </Routes>
            <Modal />
          </ModalProvider>
        </main>
        <footer className="items-center relative bg-[#f7f8fa] dark:bg-[#222222] rounded-t-2xl container">
          {/* секретная ссылка на магазин */}
          {/* <Link to={"/nintendo-eshop/shop"} className=" w-full mx-2 my-1 text-center text-sm text-gray-500 ">secret link</Link> */}
          <p className="mx-2 my-1 text-center text-sm text-gray-500 ">Email: igor.kazakov.evg@mail.ru</p>
          <p className="mx-2 my-1 text-center text-sm text-gray-500 ">ИП Казаков Игорь Евгеньевич / ИНН: 660901997402 / ОГРНИП: 322665800098991</p>
          <p className="mx-2 my-1 text-center text-sm text-gray-500 mb-2">Договор публичной оферты: <a
            href="/docs/contract_offer.docx"
            className="text-blue-700 dark:text-indigo-400"
            target="_blank"
            rel="noreferrer">скачать</a></p>
          <a href="https://metrika.yandex.ru/stat/?id=93997310&amp;from=informer"
            target="_blank" rel="noreferrer"><img src="https://informer.yandex.ru/informer/93997310/3_0_FFFFFFFF_EFEFEFFF_0_pageviews"
              style={{ width: "81px", height: "31px", border: "0" }} alt="Яндекс.Метрика" title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)" className="ym-advanced-informer hidden md:block absolute bottom-0 right-0 bott m-[2px]" data-cid="93997310" data-lang="ru" /></a>
        </footer>
      </div >
      <CookieToaster />
    </div>
  );
}

export default App;
