export const getOrder = () => (state) => state.order.entities;
export const getOrderIsLoading = () => (state) => state.order.isLoading;
export const getOrderNumber = () => (state) => state.order.entities?.orderNumber;
export const getPayUrl = () => (state) => state.order?.payUrl;
export const getOrderNumberUuid = () => (state) => state.order.entities?.orderNumberUuid;
export const getRegions = () => (state) => state.order.regionList;
export const getCurrentRegion = () => (state) => state.order.currentRegion;
export const getOrderRegion = () => (state) => state.order?.entities?.cardRegionCd;
export const getOrderError = () => (state) => state.order.error;
export const getStatus = () => (state) => state.order.orderStatus;
export const getEmailChanged = () => (state) => state.order.emailChanged;