import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentRegion, getOrderNumber, getOrderNumberUuid, getPayUrl,} from "../../store/order/selectors";
import {buyForOrder, changeOrder} from "../../store/order";
import * as yup from "yup";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {getOrderByShopAndRegion} from "../../store/order/actions";

function ShopCartTotal({ cart }) {
  const currentRegion = useSelector(getCurrentRegion());
  const dispatch = useDispatch();
  const orderNumber = useSelector(getOrderNumber());
  const payUrl = useSelector(getPayUrl());
  const orderNumberUuid = useSelector(getOrderNumberUuid());
  const [data, setData] = useState("");
  const [emailChanged, setEmailChanged] = useState(false);
  let warningMessage = '';
  let showWarning = false;
  if (!cart.userEmail || emailChanged) {
    warningMessage = 'Перед покупкой укажите свой email. На него будут отправлены счёт и коды после оплаты.';
    showWarning = true;
  } else if (cart.finalSum < 1) {
    warningMessage = 'Выберите товар для покупки.';
    showWarning = true;
  }
  useEffect(() => {
    setData(cart.userEmail);
  }, [cart]);
  const [error, setError] = useState(null);
  const schema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Введите корректную почту"
      ),
  });
  const handleChange = (e) => {
    if (e.target.value !== "") {
      schema
        .validate({ email: e.target.value })
        .then((r) => {
          setError(null);
        })
        .catch((r) => setError(r.message));
    } else {
      setError(null);
    }
    setEmailChanged(true);

    setData(e.target.value);
  };
  const handleEmail = () => {
    dispatch(
      changeOrder({
        orderNumberUuid,
        email: data,
      })
    );
    setEmailChanged(false)
  };
  const handleBuy = (e) => {
    e.preventDefault();
    window.ym(93997310, "reachGoal","clickBuyEshop");
    dispatch(buyForOrder({ orderNumberUuid }));
  };

  const clearOrder = () => {
    dispatch(
      getOrderByShopAndRegion("eshop", {
        params: { regionType: currentRegion, createOrder: true },
      })
    );
  };
  return (
    <div className=" relative my-2 flex flex-col items-center rounded-lg border bg-[#e9e9e996] px-2 py-2 dark:bg-zinc-900 ">
      <ArrowPathIcon
        className=" absolute right-2  top-2 h-5 w-5 cursor-pointer duration-300 hover:opacity-80"
        onClick={() => clearOrder()}
      />
      <div className=" mb-2 flex flex-col items-center justify-between gap-2">
        <div className=" flex flex-col items-center gap-0">
          <span className=" totalTitle">Заказ:</span>
          <span className=" totalTitle"># {orderNumber}</span>
          <span className=" totalText">{cart.caption}</span>
        </div>
        <div className=" flex flex-col items-center gap-0">
          <span className=" totalTitle">Сумма:</span>
          <span className=" totalText">{cart.originalSumText}</span>
        </div>
        <div className=" flex flex-col items-center gap-0">
          <span className=" totalTitle">Персональная скидка:</span>{" "}
          <span className=" totalText">{cart.personalDiscount}</span>
        </div>
        <div className=" flex flex-col items-center gap-0">
          <span className=" totalTitle">Всего к оплате:</span>{" "}
          <span className=" totalText">{cart.finalSumText}</span>
        </div>
      </div>

      <form
        className=" flex w-full flex-col items-center gap-0"
        onSubmit={handleBuy}
      >
        {true && (
          <span
            className={
              " mb-2 w-full overflow-hidden text-xs text-red-600 transition-all duration-300 " +
              (error ? " opacity-1 " : "  invisible opacity-0  ")
            }
          >
            {error}
          </span>
        )}
        <input
          type="text"
          className="mx-2 mb-2 w-full rounded-md border px-3 py-1.5 text-black"
          placeholder="Ваш email"
          value={data}
          onChange={(e) => handleChange(e)}
        />
        <button
          className=" mx-2 mb-2 flex w-full justify-center rounded-md bg-green-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm transition duration-300 hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:opacity-60 disabled:hover:bg-green-800"
          type="button"
          onClick={() => handleEmail()}
          disabled={!!error || !emailChanged}
        >
          Загрузить персональную скидку
        </button>
        {showWarning && (
            <span className=" mb-2 w-full overflow-hidden text-xs text-red-600 transition-all duration-300 ">
              {warningMessage}
            </span>
        )}
        <button
            className=" mx-2 mt-2  flex w-full justify-center rounded-md bg-red-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm transition duration-300 hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:opacity-60 disabled:hover:bg-red-800"
            disabled={payUrl || !cart.userEmail || emailChanged || cart.finalSum < 1}
        >
          Купить
        </button>
      </form>
    </div>
  );
}

export default ShopCartTotal;
