import React, { useContext, createContext, useState } from "react";

export const ModalContext = createContext();

export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal должен использоваться внутри ModalProvider");
  }
  return context;
}

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMedia, setModalMedia] = useState("");

  // Функция для открытия модального окна
  const openModal = (media) => {
    setModalMedia(media);
    setIsModalOpen(true);
  };

  // Функция для закрытия модального окна
  const closeModal = () => {
    setModalMedia("");
    setIsModalOpen(false);
  };
  return (
    <ModalContext.Provider
      value={{ isModalOpen, modalMedia, openModal, closeModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};
