import React, { useEffect } from "react";
import ShopCart from "../components/shop/shopCart";
import ShopRegionList from "../components/shop/shopRegionList";
import { useSelector } from "react-redux";
import { getOrderError } from "../store/order";
import { useModal } from "../hooks/useModal";
import NintendoInfo from "../components/shop/nintendoInfo";

function NintendoShop() {
  const error = useSelector(getOrderError());

  const { openModal } = useModal();
  useEffect(() => {
    if (error) openModal(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <section className="content-block my-2   py-2">
      <div className=" flex w-full flex-col items-start justify-start">
        <ShopRegionList shop={"eshop"} />
        <ShopCart shop={"eshop"} />
        <NintendoInfo />
      </div>
    </section>
  );
}

export default NintendoShop;
