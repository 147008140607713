import React, { useState } from "react";
import { Link } from "react-router-dom";

function CookieToaster() {
  const [isShow, setIsShow] = useState(
    !(
      localStorage.getItem("coockiesConfirmationInfo") &&
      +localStorage.getItem("coockiesConfirmationInfo") +
        1000 * 60 * 60 * 24 * 330 >
        Date.now()
    )
  );
  const onCoockiesConfirmation = () => {
    localStorage.setItem("coockiesConfirmationInfo", Date.now());
    setIsShow(false);
  };
  return (
    <div
      className={
        "   bottom-0 left-0   m-2 text-[#E3E5E8]    " +
        (isShow ? " fixed " : " hidden ")
      }
    >
      <div className="  flex max-w-[400px] flex-col items-center justify-between gap-2 rounded-md border bg-[#252627] p-4  dark:bg-[#252627] md:flex-row ">
        <span>
          Мы используем cookie, чтобы сайтом было удобно пользоваться.{" "}
          <Link
            to={"/cookies-info"}
            className="text-blue-700 dark:text-indigo-400"
          >
            {" "}
            Подробнее...
          </Link>
        </span>
        <button
          className="  mx-2 flex  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          target="_blank"
          rel="noreferrer"
          onClick={onCoockiesConfirmation}
        >
          Хорошо
        </button>
      </div>
    </div>
  );
}

export default CookieToaster;
