import React from "react";

function Counter({ quantity, onChange, remains }) {
  return (
    <div className="flex h-10 items-center justify-center">
      <button
        className={
          "h-full w-8 rounded-l bg-slate-300 px-2 py-1  transition duration-300 hover:opacity-80 disabled:opacity-40 disabled:hover:bg-slate-300 dark:bg-slate-600 dark:disabled:hover:bg-slate-600   "
        }
        onClick={() => onChange(quantity - 1)}
        disabled={quantity === 0}
      >
        -
      </button>
      <span
        type="number"
        className="flex h-full  w-10  items-center justify-center bg-white px-2 py-1 text-center text-sm text-black outline-none dark:bg-gray-800 dark:text-white"
      >
        {quantity}
      </span>
      <button
        className="h-full w-8 rounded-r bg-slate-300 px-2 py-1  transition duration-300 hover:opacity-80 disabled:opacity-40 disabled:hover:bg-slate-300 dark:bg-slate-600 dark:disabled:hover:bg-slate-600 "
        onClick={() => onChange(quantity + 1)}
        disabled={quantity === remains}
      >
        +
      </button>
    </div>
  );
}

export default Counter;
