import React from "react";
import { PuffLoader } from "react-spinners";

function Loader({ loading }) {
  return (
    loading && (
      <div className=" fixed  left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-black opacity-30 ">
        <PuffLoader color="#fff" />
      </div>
    )
  );
}

export default Loader;
