import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "../hoc/Container";
import {
  nintendo_img,
  playStation_img,
  steam_img,
  youtube_img,
  vk_img,
  telegram_img,
  nintendo_sale,
  steam_sale,
  playstation_sale,
} from "../../images";

function MainPage() {
  useEffect(() => {
    window.ym(93997310, "hit", "/", {
      params: {
        title: "Main Page",
      },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Container>
      <article className="  content-block">
        <h1 className="head-1">
          Коды оплаты Nintendo eShop, Steam и PlayStation
        </h1>
        <div className=" flex justify-center">
          <p className="dark-text w-full  text-justify indent-8 text-gray-700 dark:text-white ">
            Добро пожаловать в магазин блогера trendy_eng! Тут вы можете легко,
            просто и удобно купить подарочные карты для Nintendo eShop, Steam и
            PlayStation. С помощью этих кодов можно пополнить баланс в магазинах
            для покупки игр для таких платформ как Nintendo Switch, Steam (PC,
            Steam Deck), PlayStation 4 и PlayStation 5. Карты в наличии, удобная
            оплата через эквайринг или QR, моментальная доставка.
          </p>
        </div>
      </article>
      <section className=" content-block my-2 block flex-row   justify-center space-y-7 md:flex md:space-y-0">
        <div className="mx-5 basis-1/4 ">
          <Link to={"/nintendo-eshop"} className=" flex justify-center">
            <img
              className="shop-img transition duration-150 ease-in-out  hover:scale-105"
              src={nintendo_img}
              alt="Nintendo eShop"
            />
          </Link>
          <div className=" p-2 text-center">
            <h3 className="head-3 my-2 dark:text-white">Nintendo</h3>
            <p className="dark-text   text-gray-700">Польша 🇵🇱 (zl)</p>
            <p className="dark-text   text-gray-700">США 🇺🇸 ($)</p>
          </div>
        </div>
        <div className="mx-5 basis-1/4">
          <Link to={"/steam"} className=" flex justify-center">
            <img
              className="shop-img transition duration-150 ease-in-out  hover:scale-105"
              src={steam_img}
              alt="Steam"
            />
          </Link>
          <div className="p-2 text-center">
            <h3 className="head-3 my-2">Steam</h3>
            <p className="dark-text   text-gray-700">Казахстан 🇰🇿 (HKD)</p>
          </div>
        </div>
        <div className=" mx-5 basis-1/4">
          <Link to={"/playstation"} className=" flex justify-center">
            <img
              className="shop-img transition duration-150 ease-in-out  hover:scale-105"
              src={playStation_img}
              alt="PlayStation"
            />
          </Link>
          <div className="p-2 text-center">
            <h3 className="head-3 my-2">PlayStation</h3>
            <p className="dark-text itext-base text-center text-gray-700">
              Польша 🇵🇱 (zl)
            </p>
            <p className="dark-text itext-base text-center text-gray-700">
              Индия 🇮🇳 (INR)
            </p>
          </div>
        </div>
      </section>
      <section className="content-block my-2">
        <h2 className="head-2">Самые ожидаемые скидки</h2>
        <section className="my-5 block flex-row justify-center space-y-7 md:flex md:space-y-0 ">
          <div className="   mx-5 basis-1/4 overflow-hidden ">
            <Link to={"/nintendo-discount"} className=" flex justify-center">
              <img
                className=" delay-50 w-2/3 rounded object-contain transition duration-150 ease-in-out hover:scale-105  md:w-full"
                src={nintendo_sale}
                alt="Nintendo eShop"
              />
            </Link>
            <div className=" p-2">
              <h3 className="head-3 my-2 dark:text-white">Скидки Nintendo</h3>
            </div>
          </div>
          <div className=" mx-5 basis-1/4 overflow-hidden">
            <Link to={"/steam-discount"} className=" flex justify-center">
              <img
                className="delay-50 w-2/3 rounded object-contain transition duration-150 ease-in-out hover:scale-105  md:w-full"
                src={steam_sale}
                alt="Steam"
              />
            </Link>
            <div className=" p-2">
              <h3 className="head-3 my-2 dark:text-white">Скидки Steam</h3>
            </div>
          </div>
          <div className="mx-5 basis-1/4 overflow-hidden  ">
            <Link to={"/playstation-discount"} className=" flex justify-center">
              <img
                className="delay-50 w-2/3 rounded object-contain transition duration-150 ease-in-out hover:scale-105  md:w-full"
                src={playstation_sale}
                alt="PlayStation"
              />
            </Link>
            <div className=" p-2">
              <h3 className="head-3 my-2 dark:text-white">
                Скидки PlayStation
              </h3>
            </div>
          </div>
        </section>
      </section>
      <address className="content-block">
        <h2 className="head-2">
          Наши социальные сети - комментарии всегда открыты
        </h2>
        <div className="flex flex-row justify-center">
          <div className="  basis-1/5 ">
            <div className="  overflow-hidden">
              <a
                to={"https://www.youtube.com/channel/UCdfv-fZKGIobmzVxHX9mSAA"}
                href="https://www.youtube.com/channel/UCdfv-fZKGIobmzVxHX9mSAA"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => window.ym(93997310, "reachGoal", "youTubeClick")}
                className=" flex justify-center"
              >
                <img
                  className="w-1/2 transition duration-150 ease-in-out hover:scale-105 "
                  src={youtube_img}
                  alt="Trendy Shop YouTube"
                />
              </a>
              <div className=" hidden p-2 lg:block">
                <h3 className="head-3">Youtube</h3>
                <p className="dark-text text-center text-xs text-gray-700">
                  60 000+
                </p>
                <p className="dark-text text-center text-xs text-gray-700">
                  подписчиков
                </p>
              </div>
            </div>
          </div>
          <div className="basis-1/5  ">
            <div className="overflow-hidden">
              <a
                href="https://t.me/trendy_eng"
                target="_blank"
                rel="noopener noreferrer"
                className=" flex justify-center"
              >
                <img
                  className="w-1/2 transition duration-150 ease-in-out  hover:scale-105 "
                  src={telegram_img}
                  alt="Trendy Shop Telegram"
                  onClick={() => window.ym(93997310, "reachGoal", "tgClick")}
                />
              </a>
              <div className="hidden p-2 lg:block">
                <h3 className="head-3">Telegram</h3>
                <p className="dark-text  text-center text-xs text-gray-700">
                  10 000+
                </p>
                <p className="dark-text text-center text-xs text-gray-700">
                  подписчиков
                </p>
              </div>
            </div>
          </div>
          <div className=" basis-1/5 ">
            <div className=" overflow-hidden">
              <a
                href="https://vk.com/trendy_eng"
                target="_blank"
                rel="noopener noreferrer"
                className=" flex justify-center"
              >
                <img
                  className=" w-1/2 transition duration-150 ease-in-out hover:scale-105  "
                  src={vk_img}
                  alt="Trendy Shop VK"
                  onClick={() => window.ym(93997310, "reachGoal", "vkClick")}
                />
              </a>
              <div className=" hidden p-2 lg:block">
                <h3 className="head-3">Вконтакте</h3>
                <p className="dark-text text-center text-xs text-gray-700">
                  3000+
                </p>
                <p className="dark-text text-center text-xs text-gray-700">
                  подписчиков
                </p>
              </div>
            </div>
          </div>
        </div>
      </address>
    </Container>
  );
}

export default MainPage;
