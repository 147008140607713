import axios from "axios";

const apiUrl = axios.create({
   baseURL: `${process.env.REACT_APP_URL_HOST}/api/v1/`,
});

const httpService = {
   get: apiUrl.get,
   post: apiUrl.post
};

export default httpService;